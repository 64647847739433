export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (_error) => {
    // eslint-disable-next-line no-console
    console.log(_error)
  }

  // Also possible
  nuxtApp.hook('vue:error', (_error) => {
    // eslint-disable-next-line no-console
    console.log(_error)
  })
})
