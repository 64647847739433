<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="error">
    <div class="wrapper">
      <div
        v-if="error && (error.statusCode === 401 || error.statusCode === 403)"
        class="px-5"
      >
        <h4 class="title-lg py-0 my-2">Access Denied</h4>
        <span class="text-lg">
          You do not have access to this page. please contact
          compile_support@mckesson.com.
        </span>
        <br />
        <button class="btn-default cta-button-yellow" @click="onGoBack()">
          Reload page
        </button>
      </div>
      <div v-else class="px-5">
        <h4 class="title-lg py-0 my-2">Error</h4>
        <div v-if="error && error.statusCode === 404" class="text-lg">
          Sorry, this page does not exist.
        </div>
        <div v-else class="text-lg" v-html="error.message"></div>
        <br />
        <button class="btn-default cta-button-yellow" @click="onGoBack()">
          Reload page
        </button>
      </div>
      <div class="px-5">
        <img src="/images/webpage-not-found.png" alt="Not found" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useError } from '#app'

  // Composables
  const error = useError()

  // Store

  // Methods and Event Handlers
  const onGoBack = () => {
    reloadNuxtApp()
  }
</script>
<style lang="scss" scoped>
  .error {
    & .wrapper {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :deep(.cta-button-yellow) {
      font-size: 16px !important;
    }
  }
</style>
